import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const AuctionIndexPage = props => (
  <EventLayout pageTitle="Rotary/Chamber Auction">
    <p>
      The 21st Annual Claxton Rotary Club-Chamber of Commerce Benefit Auction will be held on August 20-22, 2024.  Money raised through this event support the programs of the Rotary Club and Chamber and fund college 
      scholarships awarded by both organizations.  For more information or to donate an item,  contact Jonathan Dutton, 739-0400 or the Claxton-Evans 
      County Chamber of Commerce, 739-1391. 
    </p>
  </EventLayout>
)

export default AuctionIndexPage
